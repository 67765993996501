// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1723756613819.9568";
}
// REMIX HMR END

import { useEffect, useState } from "react";
import Nav from "~/components/Nav";
import cobbfam from "~/images/cobbfam.jpg";
import cobbfam2 from "~/images/cobbfam2.jpg";
import { useOptionalUser } from "~/utils";
import HeroTextLeft from "~/components/HeroTextLeft";
import Footer from "~/components/Footer";
import HeroAndImage from "~/components/HeroAndImage";
import HeroTextRight from "~/components/HeroTextRight";
export const meta = () => [{
  title: "Chasing Summer"
}];
export default function Index() {
  _s();
  const user = useOptionalUser();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize); // Listen for resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  return <main className=" bg-white flex flex-col ">
      <Nav />
      <img className="h-full " src={isMobile ? cobbfam : cobbfam2} alt="Dunes in Pismo Beach, California" />
      <HeroAndImage />
      <HeroTextLeft />
      <HeroTextRight />
      <Footer />
    </main>;
}
_s(Index, "h/S9ABMHLHwTPmVHu+kuZaQeY3w=", false, function () {
  return [useOptionalUser];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;